import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getCustomerDetailUrl } from '../../routes';
import { CustomerListItem } from './customer-list-item';
import * as S from './styles';
import { CustomerListHeader } from './customer-list-header';
import { CustomerListProfile, useCustomerList } from '../data';
import { CustomerListItemProvider } from '../data/customer-list-item-context';

export const PAYMENT_TYPE_FILTERS = ['all', 'card', 'invoice'];

export const CustomerList = (): ReactElement => {
  const history = useHistory();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [customerItems, setCustomerItems] = useState<ReactElement[]>([]);
  const [paymentFilterIndex, setPaymentFilterIndex] = useState<number>(0);

  const getPaymentFilter = () => PAYMENT_TYPE_FILTERS[paymentFilterIndex];

  const customersResponse = useCustomerList(searchKeyword, getPaymentFilter());

  const onPaymentFilterChange = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    const index = event.target.value as number;
    setPaymentFilterIndex(index);
  };

  const onCustomerClicked = (customer: CustomerListProfile) => {
    history.push(getCustomerDetailUrl(customer.customerId));
  };

  const onSearchKeywordChange = (event: any) => {
    setSearchKeyword(event.target.value);
  };

  useEffect(() => {
    if (!customersResponse.isLoading && customersResponse.response) {
      const items = customersResponse.response.data.map(
        (customer: CustomerListProfile) => (
          <CustomerListItemProvider customer={customer}>
            <CustomerListItem
              key={customer.customerId}
              onClick={onCustomerClicked}
            />
          </CustomerListItemProvider>
        ),
      );
      setCustomerItems(items);
    }
  }, [customersResponse]);

  return (
    <S.CustomerListContainer>
      <S.ListOptions>
        <S.ListOptionTitle>Customers</S.ListOptionTitle>
        <S.SearchField
          id="customer-search"
          name="search"
          fullWidth={false}
          size="small"
          margin="dense"
          variant="outlined"
          label="Search Customers"
          onChange={onSearchKeywordChange}
        />
        <S.ListOptionTitle>Filter by payment type</S.ListOptionTitle>
        <S.FilterPaymentFormControl variant="outlined">
          <Select
            labelId="payment-type-select"
            id="payment-type-select"
            variant="outlined"
            size="small"
            margin="dense"
            value={paymentFilterIndex}
            onChange={onPaymentFilterChange}
            defaultValue={0}
          >
            {PAYMENT_TYPE_FILTERS.map((option, index) => (
              <MenuItem value={index} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </S.FilterPaymentFormControl>
      </S.ListOptions>

      <S.CustomerList>
        <CustomerListHeader />
        {customerItems}
      </S.CustomerList>
    </S.CustomerListContainer>
  );
};
