import React, { ReactElement, useEffect, useState } from 'react';
import { GSnackbar } from '../../../shared/ui';
import { StatefulResponse, SnackbarContent } from '../../../shared/models';

export const ConfirmEmailSnackbar = (
  { state }: { state: StatefulResponse<any> },
): ReactElement => {
  const [content, setContent] = useState<SnackbarContent>({
    open: false, text: '', severity: 'info',
  });

  useEffect(() => {
    if (!state.isLoading) {
      if (state.response?.success) {
        setContent({
          ...content, open: true, text: 'Email confirmed', severity: 'success',
        });
      } else if (!state.response?.success && state.response?.errorDetails.hasErrors()) {
        setContent({
          ...content, open: true, text: 'Email confirmation failed', severity: 'error',
        });
      }
    }
  }, [state]);

  return (
    <GSnackbar content={content} />
  );
};
