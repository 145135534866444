import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import * as S from './login.styles';
import { getAuthUserDefaultPath } from '../../routes';
import { useAuthUser, useCustomerLogin } from '../data';
import LoginSnackbar from './login-snackbar';
import { GaaslyError } from '../../../shared/models';

export const LoginView = (): ReactElement => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const onEmailChange = (event: any) => setEmail(event.target.value);
  const [password, setPassword] = useState('');
  const onPasswordChange = (event: any) => setPassword(event.target.value);

  const [loginEvent, setLoginEvent] = useState<number>(0);
  const onLogin = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setLoginEvent(new Date().getTime());
  };
  const loginResponse = useCustomerLogin(email, password, loginEvent);
  const authUser = useAuthUser();

  useEffect(() => {
    if (authUser) {
      history.push(getAuthUserDefaultPath(authUser));
    }
  }, [authUser]);

  const getErrorDetails = ():
    GaaslyError => loginResponse.response?.errorDetails || new GaaslyError(null);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.Avatar>
          <LockOutlinedIcon />
        </S.Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>

        <S.Form
          noValidate
          onSubmit={onLogin}
        >
          <TextField
            error={Object.prototype.hasOwnProperty.call(getErrorDetails().getErrors(), 'email')}
            helperText={getErrorDetails().getErrors().email || ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={onEmailChange}
          />
          <TextField
            error={Object.prototype.hasOwnProperty.call(getErrorDetails().getErrors(), 'password')}
            helperText={getErrorDetails().getErrors().password || ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={onPasswordChange}
          />
          <S.Submit
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Log In
          </S.Submit>
          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2" underline="hover">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2" underline="hover">
                Don&apos;t have an account? Register
              </Link>
              <br />
              <Link href="/growth-partner-sign-in" variant="body2" underline="hover">
                Looking for growth partner sign in?
              </Link>
            </Grid>
          </Grid>
        </S.Form>
      </S.Paper>
      <LoginSnackbar state={loginResponse} />
    </Container>
  );
};
