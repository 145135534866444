import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GaaslyRoutes } from './gaasly-routes';
import { isLoggedIn } from '../auth/data';

/**
 * This route is accessible for logged in users only and this can be used for forced routes,
 * so this will not check if the path is forced as these urls are already forced routes.
 * @param path
 * @param component
 * @constructor
 */
export const ForcedPrivateRoute = (
  { path, component, exact = false }: {
    path: string, component: React.ReactNode, exact?: boolean
  },
): ReactElement => (
  <Route
    path={path}
    render={() => (
      isLoggedIn()
        ? <div>{component}</div>
        : <Redirect to={GaaslyRoutes.login} />
    )}
  />
);

ForcedPrivateRoute.defaultProps = {
  exact: false,
};
