import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { GaaslyRoutes } from '../routes';

export const CustomerNavBar = (): ReactElement => {
  const history = useHistory();

  const openDashboard = () => {
    history.push(GaaslyRoutes.customerDashboard);
  };
  const openProfile = () => {
    history.push(GaaslyRoutes.customerProfile);
  };
  const openPaymentMethod = () => {
    history.push(GaaslyRoutes.paymentMethod);
  };

  return (
    <div>
      <S.NavAction color="inherit" onClick={openDashboard}>
        Dashboard
      </S.NavAction>
      <S.NavAction color="inherit" onClick={openProfile}>
        Profile
      </S.NavAction>
      <S.NavAction color="inherit" onClick={openPaymentMethod}>
        Payment method
      </S.NavAction>
    </div>
  );
};
