import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { isLoggedIn, useAuthUser } from '../features/auth';
import { GaaslyRoutes, getAuthUserDefaultPath } from '../features/routes';

// TODO find a better way to handle this...
export default function IndexPage(): ReactElement {
  const history = useHistory();
  const authUser = useAuthUser();
  if (isLoggedIn()) {
    history.push(getAuthUserDefaultPath(authUser));
  } else {
    history.push(GaaslyRoutes.login);
  }

  return (<div />);
}
