import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLoggedIn, useAuthUser } from '../auth';
import { getAuthUserDefaultPath } from './gaasly-routes';

export const PublicRoute = (
  {
    path, component, restricted = false, exact = false,
  }: {
    path: string,
    component: React.ReactNode,
    restricted?: boolean
    exact?: boolean
  },
): ReactElement => {
  const authUser = useAuthUser();
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        isLoggedIn() && restricted
          ? <Redirect to={getAuthUserDefaultPath(authUser)} />
          : component
      )}
    />
  );
};

PublicRoute.defaultProps = {
  exact: false,
  restricted: false,
};
