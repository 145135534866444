import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider as SThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';

import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  ForcedPrivateRoute, GaaslyRoutes, PrivateRoute, PublicRoute,
} from '../features/routes';
import IndexPage from '../pages/index-page';
import {
  ContractPage,
  CustomerDashboardPage,
  CustomerListPage,
  EmailConfirmationPage,
  GpCustomerPage,
  GrowthPartnerDashboardPage,
  InvitationRegisterPage,
  LoginPage,
  PaymentMethodPage,
  RegisterEmailSentPage,
  RegisterPage,
  ResetPasswordConfirmPage,
  ResetPasswordPage,
  TimeTrackingPage,
} from '../pages';
import { NavBar } from '../features/nav-bar';
import {
  GoogleSignIn, loadAuthUser, logout, useAuthUser,
} from '../features/auth';
import { Logger } from '../shared/utils';
import { store } from './store';
import { theme } from '../features/theme';
import { CustomerOnboarding, GrowthPartnerOnboarding } from '../features/onboarding';
import { useIsNavBar } from '../features/nav-bar/use-is-nav-bar';
import { CustomerProfilePage } from '../features/profile/customer-profile/customer-profile-page';

const googleOAuthClientId: string = process.env.REACT_APP_GOOGLE_OAUTH_CLIEND_ID || '';

// Make sure to call `loadStripe` outside of a component's render to avoid recreating
const stripeApiKey: string = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
const stripePromise = loadStripe(stripeApiKey);

function initSentry() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (environment === 'DEVELOPMENT') return;

  const tracesSampleRate = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 0.1 : 1.0;
  Sentry.init({
    dsn: 'https://6ffd9f2401374578902210ec9396ec79@o973649.ingest.sentry.io/5931835',
    integrations: [new Integrations.BrowserTracing()],
    environment,
    tracesSampleRate,
  });
}

function Content(): ReactElement {
  const isVisible = useIsNavBar();
  const topMargin = isVisible ? 70 : 0;

  return (
    <div style={{ marginTop: topMargin }}>
      <Switch>
        <PublicRoute
          restricted={false}
          exact
          path="/"
          component={<IndexPage />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.login}
          component={<LoginPage />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.register}
          component={<RegisterPage />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.growthPartnerSignIn}
          component={<GoogleSignIn />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.invitationRegister}
          component={<InvitationRegisterPage />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.confirmEmailSent}
          component={<RegisterEmailSentPage />}
        />
        <PublicRoute
          restricted
          path={GaaslyRoutes.resetPassword}
          component={<ResetPasswordPage />}
        />
        <PublicRoute
          restricted={false}
          path={GaaslyRoutes.resetPasswordConfirm}
          component={<ResetPasswordConfirmPage />}
        />
        <PublicRoute
          restricted={false}
          path={GaaslyRoutes.confirmEmail}
          component={<EmailConfirmationPage />}
        />
        {/* Customer routes */}
        <PrivateRoute
          path={GaaslyRoutes.customerDashboard}
          component={<CustomerDashboardPage />}
        />
        <PrivateRoute
          path={GaaslyRoutes.customerProfile}
          component={<CustomerProfilePage />}
        />
        <PrivateRoute
          path={GaaslyRoutes.paymentMethod}
          component={<PaymentMethodPage />}
        />
        {/* Growth Partner routes */}
        <PrivateRoute
          path={GaaslyRoutes.timeTracking}
          component={<TimeTrackingPage />}
        />
        <PrivateRoute
          path={GaaslyRoutes.growthPartnerDashboard}
          component={<GrowthPartnerDashboardPage />}
        />
        <PrivateRoute
          path={GaaslyRoutes.customerDetail}
          component={<GpCustomerPage />}
        />
        <PrivateRoute
          path={GaaslyRoutes.customersListing}
          component={<CustomerListPage />}
        />
        <ForcedPrivateRoute
          path={GaaslyRoutes.growthPartnerOnboarding}
          component={<GrowthPartnerOnboarding />}
        />
        <ForcedPrivateRoute
          path={GaaslyRoutes.userContracts}
          component={<ContractPage />}
        />
        <ForcedPrivateRoute
          path={GaaslyRoutes.customerOnboarding}
          component={<CustomerOnboarding />}
        />
      </Switch>
    </div>
  );
}

function App(): ReactElement {
  initSentry();
  useEffect(() => {
    loadAuthUser().then((response) => {
      Logger.log(`App loadAuthUser - response ${JSON.stringify(response)}`);
    });
  }, []);

  const authUser = useAuthUser();
  useEffect(() => {
    if (!authUser) {
      logout();
    }
  }, [authUser]);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SThemeProvider theme={theme}>
            <GoogleOAuthProvider clientId={googleOAuthClientId}>
              <Elements stripe={stripePromise}>
                <CssBaseline />
                <Router>
                  <NavBar />
                  <Content />
                </Router>
              </Elements>
            </GoogleOAuthProvider>
          </SThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>

  );
}

export default App;
