import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import * as S from './register.styles';
import { RegistrationInfo, useCustomerRegister } from '../data';
import { RegisterSnackbar } from './register-snackbar';
import { GaaslyRoutes } from '../../routes';

export const RegisterView = (): ReactElement => {
  const history = useHistory();

  const [user, setUser] = useState<RegistrationInfo>({
    email: '',
    password1: '',
    password2: '',
  });

  const [registerEvent, setRegisterEvent] = useState<number>(0);
  const onRegister = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setRegisterEvent(new Date().getTime());
  };

  const registerResponse = useCustomerRegister(user, registerEvent);

  useEffect(() => {
    if (registerResponse.response?.success) {
      history.push(GaaslyRoutes.confirmEmailSent);
    }
  }, [registerResponse]);

  const onEmailChange = (event: any) => setUser({
    ...user, email: event.target.value,
  });
  const onPassword1Change = (event: any) => setUser({
    ...user, password1: event.target.value,
  });
  const onPassword2Change = (event: any) => setUser({
    ...user, password2: event.target.value,
  });

  const errors = (): any => registerResponse.response?.errorDetails.getErrors() || {};

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.Avatar>
          <LockOutlinedIcon />
        </S.Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <S.Form
          noValidate
          onSubmit={onRegister}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={Object.prototype.hasOwnProperty.call(errors(), 'email')}
                helperText={errors().email || ''}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={onEmailChange}
                value={user.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Object.prototype.hasOwnProperty.call(errors(), 'password1')}
                helperText={errors().password1 || ''}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password1"
                autoComplete="current-password"
                onChange={onPassword1Change}
                value={user.password1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Object.prototype.hasOwnProperty.call(errors(), 'password2')}
                helperText={errors().password2 || ''}
                variant="outlined"
                required
                fullWidth
                name="password2"
                label="Password again"
                type="password"
                id="password2"
                autoComplete="current-password"
                onChange={onPassword2Change}
                value={user.password2}
              />
            </Grid>
          </Grid>
          <S.Submit
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Register
          </S.Submit>

          <Typography>
            By registering you confirm that you have read and agree to the
            <Link href="https://www.gaasly.com/terms/" variant="body2" underline="hover">
              {' Terms of Use '}
            </Link>
            and
            <Link
              href="https://www.gaasly.com/privacy-policy/"
              variant="body2"
              underline="hover"
            >
              {' Privacy Policy '}
            </Link>
          </Typography>

          <S.Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2" underline="hover">
                Already have an account? Log in
              </Link>
            </Grid>
          </S.Grid>
        </S.Form>

      </S.Paper>

      <RegisterSnackbar state={registerResponse} />
    </Container>
  );
};
