import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GaaslyRoutes } from './gaasly-routes';
import { isLoggedIn } from '../auth';
import { getStoreAuthUser } from '../auth/data/token-store';

export const hasAuthUserPendingContracts = (): boolean => {
  const authUser = getStoreAuthUser();
  return authUser?.hasPendingContracts ?? false;
};

export const isCustomer = () => {
  const authUser = getStoreAuthUser();
  return authUser?.isCustomer;
};

export const isGrowthPartner = () => {
  const authUser = getStoreAuthUser();
  return authUser?.isGrowthPartner;
};

export const hasPendingOnboarding = () => {
  const authUser = getStoreAuthUser();
  return !authUser?.isOnboardingDone;
};

/**
 * PrivateRoute will be only accessible to authenticate users. This will redirect users to login
 * page in case not authenticated and check if there is need to navigate to forced route, ie.
 * Contract for signing pending contracts.
 * @param path
 * @param component
 * @constructor
 */
export const PrivateRoute = (
  {
    path, component, exact = false,
  }: {
    path: string, component: React.ReactNode, exact?: boolean
  },
): ReactElement => (
  <Route
    path={path}
    exact={exact}
    render={() => {
      const authUser = getStoreAuthUser();

      if (!isLoggedIn()) {
        return <Redirect push from={path} to={GaaslyRoutes.login} />;
      }
      if (authUser?.isGrowthPartner && !authUser.isOnboardingDone) {
        return <Redirect push from={path} to={GaaslyRoutes.growthPartnerOnboarding} />;
      }
      if (authUser?.isCustomer && !authUser.isOnboardingDone) {
        return <Redirect push from={path} to={GaaslyRoutes.customerOnboarding} />;
      }
      if (hasAuthUserPendingContracts()) {
        return <Redirect push from={path} to={GaaslyRoutes.userContracts} />;
      }
      return component;
    }}
  />
);

PrivateRoute.defaultProps = {
  exact: false,
};
