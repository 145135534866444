import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as S from './register.styles';
import { InvitationRegisterSnackbar } from './invitation-register-snackbar';
import { InvitationRegistrationInfo } from '../../../shared/models';
import { getAuthUserDefaultPath } from '../../routes';
import { useAuthUser, useInvitationRegister } from '../data';

type InvitationParams = {
  key: string
}

export const InvitationRegisterView = (): ReactElement => {
  const history = useHistory();
  const params = useParams();
  const invitationParams: InvitationParams = JSON.parse(JSON.stringify(params));
  const authUser = useAuthUser();

  const [data, setData] = useState<InvitationRegistrationInfo>({
    key: invitationParams.key,
    password1: '',
    password2: '',
  });

  const [submit, setSubmit] = useState<number>(0);
  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setSubmit(new Date().getTime());
  };
  const state = useInvitationRegister(data, submit);
  useEffect(() => {
    if (!state.isLoading && state.response && state.response.success) {
      history.push(getAuthUserDefaultPath(authUser));
    }
  }, [state]);
  const errors = (): any => state.response?.errorDetails.getErrors() || {};

  const onPassword1Change = (event: any) => setData({
    ...data, password1: event.target.value,
  });
  const onPassword2Change = (event: any) => setData({
    ...data, password2: event.target.value,
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.GaaslyLogo />
        <S.Header variant="h5">
          Finish Registration
        </S.Header>
        <S.Text>
          Set password to finish registration
        </S.Text>
        <S.Form
          noValidate
          onSubmit={onSubmit}
        >
          <S.TextField
            error={Object.prototype.hasOwnProperty.call(errors(), 'password1')}
            helperText={errors().password1 || ''}
            name="password1"
            variant="outlined"
            fullWidth
            id="password1"
            label="Password *"
            type="password"
            value={data.password1}
            onChange={onPassword1Change}
          />
          <S.TextField
            error={Object.prototype.hasOwnProperty.call(errors(), 'password2')}
            helperText={errors().password2 || ''}
            name="password2"
            variant="outlined"
            fullWidth
            id="password2"
            label="Password again *"
            type="password"
            value={data.password2}
            onChange={onPassword2Change}
          />
          <S.TextField
            type="submit"
            fullWidth
            // variant="contained"
            color="secondary"
          >
            Register
          </S.TextField>

          <Typography>
            By registering you confirm that you have read and agree to the
            <Link href="https://www.gaasly.com/terms/" variant="body2" underline="hover">
              {' Terms of Use '}
            </Link>
            and
            <Link
              href="https://www.gaasly.com/privacy-policy/"
              variant="body2"
              underline="hover"
            >
              {' Privacy Policy '}
            </Link>
          </Typography>

        </S.Form>
      </S.Paper>
      <InvitationRegisterSnackbar state={state} />
    </Container>
  );
};
