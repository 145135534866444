import React, { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { useConfirmEmail } from '../data';
import * as S from './styles';
import { ConfirmEmailSnackbar } from './confirm-email-snackbar';
import { getAuthUserDefaultPath } from '../../routes';
import { useAuthUser } from '../../auth';

export const EmailConfirmationView = (): ReactElement => {
  const history = useHistory();
  const authUser = useAuthUser();
  const params = useParams();

  const { key } = JSON.parse(JSON.stringify(params));
  const [state, dispatch] = useConfirmEmail(key);

  const navigateToPlatform = () => {
    history.push(getAuthUserDefaultPath(authUser));
  };

  useEffect(() => {
    dispatch({ type: 'confirm' });
  }, []);

  useEffect(() => {
    if (!state.isLoading && state.response?.success) {
      setTimeout(navigateToPlatform, 3000);
    }
  }, [state]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <S.Paper>
        <S.GaaslyLogo />
        <S.Header variant="h5">
          Email confirmation
        </S.Header>
        <S.Text>
          Confirming your email...
        </S.Text>
      </S.Paper>
      <ConfirmEmailSnackbar state={state} />
    </Container>
  );
};
