import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { useListUserContracts, UserContract } from '../data';
import { ContractDisplayCustomerOnboarding } from './contract-display-customer-onboarding';
import { ContractDisplayCustomerUpdate } from './contract-display-customer-update';
import { ContractSignSection } from './contract-sign-section';
import { ContractDisplayGpFirst } from './contract-display-gp-first';
import { ContractDisplayGpUpdate } from './contract-display-gp-update';
import { getStoreAuthUser } from '../../auth/data/token-store';
import { CustomerDetailProfile } from '../../customers';

export const ContractView = (
  { nextCb }: { nextCb?: () => void },
): React.ReactElement => {
  const history = useHistory();
  const [user, setUser] = useState(getStoreAuthUser);
  const [userContract, setUserContract] = useState<UserContract | null>(null);
  const contractsState = useListUserContracts(user?.userId || '');

  useEffect(() => {
    setUserContract(contractsState.response?.data.pop() || null);
  }, [contractsState]);

  if (user == null || userContract == null) {
    return <div />;
  }

  const onAgreed = () => {
    if (nextCb) {
      // Part of onboarding and let onboarding know we are ready to navigate further
      setTimeout(nextCb, 2000);
    } else {
      // Individual page outside of onboarding and we want to navigate back to the requested page
      setTimeout(history.goBack, 2000);
    }
  };

  /*
  We need to get rid off this hardcoding different contract UI old-components based on data and
  createa UI component that fetches needed data from database and adds to UI. In case any changes to
  these, just scrap this whole thing.
  */
  let displayComponent = <div />;
  if (user.isCustomer) {
    const customer = user as CustomerDetailProfile;
    if (!customer.hasSignedContracts && !user.isOnboardingDone) {
      displayComponent = <ContractDisplayCustomerOnboarding userContract={userContract} />;
    } else {
      displayComponent = <ContractDisplayCustomerUpdate userContract={userContract} />;
    }
  }
  if (user.isGrowthPartner) {
    if (!user.hasSignedContracts) {
      displayComponent = <ContractDisplayGpFirst userContract={userContract} />;
    } else {
      displayComponent = <ContractDisplayGpUpdate userContract={userContract} />;
    }
  }

  return (
    <S.Container>
      {displayComponent}
      <ContractSignSection
        user={user}
        userContract={userContract}
        onAgreed={onAgreed}
      />
    </S.Container>
  );
};

ContractView.defaultProps = {
  nextCb: null,
};
