import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NonNavBarRouters } from '../routes';

export const isNavBarVisible = (url: string): boolean => {
  const index = NonNavBarRouters.indexOf(url);
  const isNavBarUrl = index < 0;
  return isNavBarUrl;
};

export const useIsNavBar = (): boolean => {
  const history = useHistory();
  const url = window.location.pathname;
  const [isVisible, setIsVisible] = useState<boolean>(isNavBarVisible(url));

  useEffect(() => {
    const unregisteCb = history.listen((location) => {
      const visible = isNavBarVisible(location.pathname);
      setIsVisible(visible);
    });
    return unregisteCb;
  }, [history]);

  return isVisible;
};
